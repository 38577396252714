import ImageResize from 'image-resize'

import {
    IStickers,
    IGeolocation,
    ICommunity,
    ICounts,
} from 'interfaces'
import {
    MINIMUM_AGE_REGISTRATION,
    LINKER_DOMAIN,
    POST_IMAGE_MAX_SIDE_SIZE,
    POST_COMMUNITY_CHECKS_ID,
    FIREBASE_WEB_API_KEY,
} from 'config/app'
import { HOSTNAME, API_URL, API_CANARY_PATH } from 'config/api'
import { fetchCountResponseType } from 'services/UserService'
import requestClient, { requestClientInstance } from 'utils/requestClient'
import { getFileImageElement } from 'utils/helpers'
import errorLog from 'utils/errorLog'

export type TFireBaseApiError = {
    error: {
        code: number
        message: string
        status: string
    }
}

export type TNormalizeImageFileProps = {
    imageType?: string
    imageMaxSide?: number
}

export type TDynamicLinkProps = {
    link: string
}

export type TDynamicLinkResponse = {
    shortLink: string
}

/**
 * Service App
 */
class AppService {
    static fetchStickers() {
        return requestClient<IStickers>(API_URL.stickers)
    }

    /**
     * Get user geolocation current position
     */
    static getCurrentPosition(): Promise<IGeolocation> {
        return new Promise((resolve, reject) => {
            window.navigator.geolocation.getCurrentPosition((position) => {
                resolve({
                    lat: position.coords.latitude,
                    lon: position.coords.longitude,
                })
            }, (err) => {
                reject(err)
            })
        })
    }

    /**
     * Получить максимально раннюю дату для регистрации
     */
    static getDateMinimumAgeRegistration(): Date {
        const date = new Date()
        const dateEndTimestamp = date.setFullYear(date.getFullYear() - MINIMUM_AGE_REGISTRATION)

        return new Date(dateEndTimestamp)
    }

    /**
     * Получить количество обновленных значений счетчика
     */
    static getCounterCount(counter: keyof ICounts, value: fetchCountResponseType): number | undefined {
        switch (counter) {
            case 'support_count': {
                return Object.values((value as { [key: string]: string[] })).reduce((acc, val) => {
                    return acc + val.length
                }, 0)
            }

            default:
                errorLog(`${counter}: do not handled`)
                return undefined
        }
    }

    /**
     * Получить список сообществ: все или за исключением Чеков
     */
    static getCommunities(communities: ICommunity[]) {
        const filteredCommunities = communities.filter((item) => item.community.id !== POST_COMMUNITY_CHECKS_ID)
        return filteredCommunities.length === 1 ? filteredCommunities : communities
    }

    /**
     * @deprecated
     * Получить ссылку с utm и параметрами приложения для редиректа
     */
    static getRedirectUrl(params: Record<string, any>, linkParams: string = '', path: string = ''): string {
        const APN = 'com.sessia.kickbacks'
        const LINK = `https://${HOSTNAME}`
        const OFL = 'https://kicks-back.com'
        const IBI = 'com.kickback'
        const ISI = '1546115424'

        const {
            id,
            member_id,
            invite,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content,
        } = params

        const isAccountMember = path === 'account' && id && !invite
        const link = LINK
        const ofl = OFL

        const urlParams = new URLSearchParams({ link: `${link}${linkParams}` })
        const oflParams = new URLSearchParams(isAccountMember ? { invite: id } : {})

        if (member_id || invite) {
            oflParams.set('invite', invite || member_id)

            urlParams.set('link', `${urlParams.get('link')}?${oflParams.toString()}`)
            urlParams.set('ofl', `${ofl}?${oflParams.toString()}`)
        } else {
            const oflParamsString = oflParams.toString()
            const urlParamsLink = urlParams.get('link') || ''

            urlParams.set('link', oflParamsString ? `${urlParamsLink}?${oflParamsString}` : urlParamsLink)
            urlParams.set('ofl', isAccountMember ? `${ofl}?${oflParamsString}` : ofl)
        }

        if (utm_source) {
            urlParams.set('utm_source', utm_source)
            urlParams.set('pt', utm_source)
        } else {
            urlParams.set('utm_source', 'share')
            urlParams.set('pt', 'share')
        }

        if (utm_medium) {
            urlParams.set('utm_medium', utm_medium)
            urlParams.set('mt', utm_medium)
        } else if (invite || isAccountMember) {
            urlParams.set('utm_medium', invite || id)
            urlParams.set('mt', invite || id)
        } else {
            urlParams.set('utm_medium', 'no_invite')
            urlParams.set('mt', 'no_invite')
        }

        if (utm_campaign) {
            urlParams.set('utm_campaign', utm_campaign)
            urlParams.set('ct', utm_campaign)
        } else {
            const value = encodeURIComponent(window.location.href)

            urlParams.set('utm_campaign', value)
            urlParams.set('ct', value)
        }

        if (utm_term) {
            urlParams.set('utm_term', utm_term)
        }
        if (utm_content) {
            urlParams.set('utm_content', utm_content)
        }

        urlParams.set('apn', APN)
        urlParams.set('ibi', IBI)
        urlParams.set('isi', ISI)

        return `${LINKER_DOMAIN}?${urlParams.toString()}`
    }

    /**
     * Upload image as Base64
     */
    static uploadImageToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.readAsDataURL(file)
            reader.onload = () => {
                const imgBase64 = (reader.result as string)

                if (imgBase64) {
                    return resolve(imgBase64)
                }

                return reject(new Error('Photo invalid'))
            }
            reader.onerror = () => {
                return reject(new Error('Photo invalid'))
            }
        })
    }

    /**
     * Normalize upload image file
     */
    static normalizeImageFile(
        file: File,
        { imageType = 'image/jpeg', imageMaxSide = POST_IMAGE_MAX_SIDE_SIZE }: TNormalizeImageFileProps = {},
    ) {
        return getFileImageElement(file)
            .then((image) => {
                const { naturalWidth, naturalHeight } = image
                const isBigWidth = naturalWidth > imageMaxSide
                const isBigHeight = naturalHeight > imageMaxSide

                let width = naturalWidth
                let height = naturalHeight

                if (isBigWidth || isBigHeight) {
                    const ratio = Math.min(naturalWidth, naturalHeight) / Math.max(naturalWidth, naturalHeight)

                    width = naturalWidth > naturalHeight ? imageMaxSide : Math.round(imageMaxSide * ratio)
                    height = naturalWidth > naturalHeight ? Math.round(imageMaxSide * ratio) : imageMaxSide
                }

                if (file.type !== imageType || isBigWidth || isBigHeight) {
                    const imageResize = new ImageResize({
                        quality: 90,
                        format: 'jpeg',
                        outputType: 'blob',
                        width,
                        height,
                    })

                    let newFile: File

                    return imageResize
                        .play(file)
                        .then((blob) => {
                            if (blob instanceof Blob) {
                                newFile = new File([blob], `${Date.now()}.jpg`, { type: blob.type })
                                return getFileImageElement(newFile)
                            }
                            return Promise.reject()
                        })
                        .then((newImage) => {
                            return { file: newFile, image: newImage }
                        })
                        .catch(() => {
                            return Promise.reject()
                        })
                }

                return { file, image }
            })
    }

    /**
     * Переключение режима API на канарейку
     */
    static setRequestCanaryZone() {
        requestClientInstance.defaults.baseURL = API_CANARY_PATH
    }

    /**
     * Create dynamic links with the Firebase REST API
     * @see https://firebase.google.com/docs/dynamic-links/rest
     */
    static fetchDynamicLink({ link }: TDynamicLinkProps): Promise<TDynamicLinkResponse> {
        return fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${FIREBASE_WEB_API_KEY}`, {
            method: 'POST',
            body: JSON.stringify({ longDynamicLink: `${LINKER_DOMAIN}/?link=${link}` }),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json()
                }

                return Promise.reject(res)
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

export default AppService
