import React, { ReactNode } from 'react'
import {
    Route,
    RouteComponentProps,
    Redirect,
    generatePath,
} from 'react-router-dom'

import { APP_URL, URL_PARAM_RETURN_PATH_KEY } from 'config/app'
import { useApp } from 'containers/App/hooks'
import { getURLSearchParams, setUrlSearchParams } from 'utils/helpers'

type RouteClientPropType = {
    isAuthorized: boolean
    isPrivate?: boolean
    component: React.ComponentType<any>
    path: string
    exact?: boolean
}

type ParamsType = { [key: string]: string | undefined }

const RouteClient: React.FC<RouteClientPropType> = ({
    isAuthorized,
    isPrivate = false,
    component: Component,
    ...rest
}) => {
    const authPath = APP_URL.auth
    const accountPattern = /\/account\/[-_a-zA-Z0-9]+/

    const { mainPrivateRoute: defaultPrivatePath } = useApp()

    const handlerRouting = (props: RouteComponentProps<ParamsType>): ReactNode => {
        const { location: { pathname, search, hash } } = props
        const urlSearchParams = setUrlSearchParams(getURLSearchParams(search), false)

        const isPostPath = /\/post\/\d+/.test(pathname)
        const isAccountPath = accountPattern.test(pathname) // /account/ECV-000000
        const isUserPath = /\/user\/\d+/.test(pathname)
        const isStorePath = /\/store\/\d+/.test(pathname)
        const isGoodsPath = /\/goods\/\d+/.test(pathname)
        const isGoodsPathOld = /\/goods\/\w+\.html/.test(pathname) // /goods/a3.html#store=6&goods=34462...
        const isCommunityPath = /\/community\/\d+/.test(pathname)
        const isSMSPath = /\/app/.test(pathname)
        const isKickPath = /\/kick\/\d+/.test(pathname)

        const isServicePath = /\/service/.test(pathname) // app services paths

        // Redirect from old goods path to new goods path
        if (isGoodsPathOld && hash) {
            const [, goodsSearch] = hash.split('#')
            const { goods, store, ...goodsParams } = getURLSearchParams(goodsSearch)
            const goodsUrlSearchParams = setUrlSearchParams(goodsParams, false)

            if (goods) {
                Object.keys(goodsParams).forEach((key) => {
                    goodsUrlSearchParams.set(key, goodsParams[key])
                })

                return (
                    <Redirect
                        to={{
                            pathname: generatePath(APP_URL.goods, { id: goods }),
                            search: goodsUrlSearchParams.toString(),
                        }}
                    />
                )
            }
        }

        if (isAuthorized) {
            const isPathToAbsentPage = isAccountPath || isUserPath || isCommunityPath || isSMSPath || isKickPath

            if (!isPrivate || isPathToAbsentPage || (isServicePath && process.env.NODE_ENV !== 'development')) {
                return <Redirect to={defaultPrivatePath} />
            }

            return <Component {...props} />
        }

        // KW-53
        // Set path for redirect like return url-parameter
        if (isPostPath || isStorePath || isGoodsPath) {
            urlSearchParams.set(URL_PARAM_RETURN_PATH_KEY, pathname)
        }
        // Set guarantor code like id url-parameter
        if (isAccountPath) {
            const [accountMatch] = pathname.match(accountPattern) || []
            const [, accountId] = accountMatch ? accountMatch.split('/account/') : []

            if (accountId) {
                urlSearchParams.set('id', accountId)
            }
        }

        return isPrivate
            ? <Redirect to={{ pathname: authPath, search: urlSearchParams.toString() }} />
            : <Component {...props} />
    }

    return (
        <Route {...rest} render={handlerRouting} />
    )
}

export default RouteClient
