import { AxiosResponse, AxiosError } from 'axios'

import {
    IAuth,
    IAuthExtends,
    IAuthData,
    IUser,
    IDarkDoorProps,
} from 'interfaces'
import {
    IS_PRODUCTION,
    API_HOST,
    API_URL,
} from 'config/api'
import {
    APP_URL,
    AUTH_PACKAGE,
    GRANT_TYPE_SMS,
    GRANT_TYPE_CALL,
    SMS_AUTH_CLIENT_ID,
    SMS_CLIENT_SECRET,
    CALL_AUTH_CLIENT_ID,
    CALL_CLIENT_SECRET,
    PHONE_ID,
    CHECK_CALL_AUTH_TIMER,
    USER_TAG_ID_CANARY_ZONE,
    URL_PARAM_RETURN_PATH_KEY,
} from 'config/app'

import { authorizeUser, fetchUser, fetchUserTags } from 'containers/User/user-actions'
import {
    AppService,
    StorageService,
    UserService,
} from 'services'
import requestClient from 'utils/requestClient'
import { getURLSearchParams } from 'utils/helpers'
import errorLog from 'utils/errorLog'

import store, { AppDispatch } from 'store'
import i18n from 'i18n'

export type authPropType = {
    grant_type: string
    package: string
    phone_id: string
    client_id: string
    client_secret: string
}

export type authDataPropType = {
    country_id: number// | string
    country_code: string
    phone_number: string
    name?: string
    surname?: string
    validation_code?: string
    guarantor_code?: string
}

export type authResponseErrorType = {
    error: string // access_denied|...
    error_description: string
}

export type authResponseType = IAuth | authResponseErrorType

export type confirmSMSAuthPropType = authDataPropType & { validation_code: string }

export type confirmCallAuthPropType = authDataPropType & { check_id: string }

export type getCallAuthResponseType = {
    check_id: string
    call_phone: string
    call_phone_pretty: string
}

/**
 * Service API authentication
 *
 * @class AuthService
 */
class AuthService {
    protected params: authPropType
    protected isAbortConfirmCallAuth: boolean

    constructor() {
        /**
         * @protected
         * @type object
         * @property {string} grant_type
         * @property {string} package
         * @property {string} phone_id
         * @property {string} client_id
         * @property {string} client_secret
         */
        this.params = {
            grant_type: GRANT_TYPE_SMS,
            package: AUTH_PACKAGE,
            phone_id: PHONE_ID,
            client_id: SMS_AUTH_CLIENT_ID,
            client_secret: SMS_CLIENT_SECRET,
        }

        /**
         * @protected
         * @type boolean
         */
        this.isAbortConfirmCallAuth = false
    }

    /**
     * Check user has access and refresh tokens
     *
     * @return boolean
     */
    static isAuthenticated(): boolean {
        const { access_token, refresh_token } = AuthService.getAuthData() || {}
        return !!access_token && !!refresh_token
    }

    /**
     * Check required dark door auth params
     *
     * @param {IDarkDoorProps} params
     * @return boolean
     */
    static isDarkDoorAuth(params: IDarkDoorProps) {
        return !!params?.token && !!params?.company_id
    }

    /**
     * Get dark door auth url params
     *
     * @return IDarkDoorProps
     */
    static getDarkDoorUrlParams(search: string = ''): IDarkDoorProps {
        const { token, company_id } = getURLSearchParams(search)
        return { token, company_id }
    }

    /**
     * Get user auth data from local storage
     *
     * @return {null|IAuthData}
     */
    static getAuthData(): null | IAuthExtends {
        return StorageService.getItem('auth')
    }

    /**
     * Save user auth data to local storage
     *
     * @param {IAuth} params
     * @return void
     */
    static saveAuthData(params: IAuthExtends): void {
        StorageService.setItem('auth', params)
    }

    /**
     * Save dark door auth data
     *
     * @param {object} params
     * @property {string} access_token
     * @return void
     */
    static saveDarkDoorAuthData(params: Partial<IAuthData>): void {
        StorageService.setItem('auth', params)
    }

    /**
     * Clear user auth data from local storage
     *
     * @return void
     */
    static clearAuthData(): void {
        StorageService.removeItem('auth')
    }

    /**
     * Запрос авторизации по телефону
     * @param {string} phone
     *
     * @return {Promise<{data:getCallAuthResponseType}>}
     */
    static getCallAuth(phone: string) {
        return requestClient<getCallAuthResponseType>(API_URL.requestCallAuth, { method: 'post', data: { phone } })
    }

    /**
     * Get access and refresh tokens
     *
     * @param {authDataPropType} data
     * @return authResponseType
     */
    auth(data: authDataPropType): Promise<authResponseType> {
        const defaults = { package: AUTH_PACKAGE, phone_id: PHONE_ID }

        const defaultsUserMode = {
            grant_type: GRANT_TYPE_SMS,
            client_id: SMS_AUTH_CLIENT_ID,
            client_secret: SMS_CLIENT_SECRET,
        }

        const params = { ...defaults, ...data, ...defaultsUserMode }

        return new Promise((resolve, reject) => {
            requestClient(API_URL.auth, { baseURL: API_HOST, params })
                .then((res: AxiosResponse<authResponseType>) => {
                    const { data: dataRes } = res || {}

                    if ((dataRes as IAuth)?.access_token) {
                        const authExtends: IAuthExtends = {
                            ...dataRes as IAuth,
                            grantType: defaultsUserMode.grant_type,
                        }

                        const authData: IAuthData = {
                            ...authExtends,
                            countryId: params.country_id,
                            countryCode: params.country_code,
                            phone: params.phone_number,
                        }

                        AuthService.saveAuthData(authExtends)
                        UserService.saveAuthData(authData)
                    }

                    return resolve(dataRes)
                })
                .catch((err: AxiosError) => {
                    return reject(err)
                })
        })
    }

    /**
     * Авторизация в пользовательской версии
     *
     * @param {IUser} user
     * @param location
     * @return {Promise<void|string>} - route for redirect or void with set authorization
     */
    static authorize(user: IUser, location: any): Promise<void | string> {
        const { dispatch }: { dispatch: AppDispatch } = store

        return new Promise((resolve, reject) => {
            if (user) {
                return AuthService.checkUserAccounts(user)
                    .then((path) => {
                        if (path) {
                            return Promise.reject({ path })
                        }

                        return AuthService.checkUserProfile(user)
                    })
                    .then((path) => {
                        if (path) {
                            return Promise.reject({ path })
                        }

                        return AuthService.checkUserCompanyAccount(user)
                    })
                    .then(() => {
                        return AuthService.checkUserTagCanary(user)
                    })
                    .then(() => {
                        const { [URL_PARAM_RETURN_PATH_KEY]: path } = getURLSearchParams(location.search)

                        dispatch(authorizeUser(true))

                        return path ? resolve(path) : resolve()
                    })
                    .catch((e) => {
                        const { path } = e || {}

                        if (path) {
                            return resolve(path)
                        }

                        return reject(e)
                    })
            }

            return reject()
        })
    }

    /**
     * Проверка юзера на наличие аккаунтов компаний
     *
     * @param user
     * @return Promise<void|string>
     */
    static checkUserAccounts(user: IUser): Promise<void | string> {
        const { search } = window.location

        return new Promise((resolve) => {
            return UserService.isSetAccounts(user) ? resolve() : resolve(`${APP_URL.registrationUser}${search}`)
        })
    }

    /**
     * Проверка пользователя на заполненность обязательных полей
     *
     * @param user
     * @return Promise<void|string>
     */
    static checkUserProfile(user: IUser): Promise<void | string> {
        const { search } = window.location
        const { name, surname } = user // see RegistrationUserAction required fields

        return new Promise((resolve) => {
            return name && surname ? resolve() : resolve(`${APP_URL.registrationUser}${search}`)
        })
    }

    /**
     * Проверка пользователя на текущую компанию
     *
     * @param user
     * @return Promise<void>
     */
    static checkUserCompanyAccount(user: IUser): Promise<void> {
        const { dispatch }: { dispatch: AppDispatch } = store
        const companyAccountId5 = UserService.getCompanyAccountId5(user)

        return new Promise((resolve, reject) => {
            if (companyAccountId5 && companyAccountId5.company_id !== user.company_id) {
                return UserService.changeAccount(companyAccountId5.id)
                    .then(() => {
                        return dispatch(fetchUser())
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        return reject(err)
                    })
            }

            return resolve()
        })
    }

    /**
     * Проверка пользователя на наличие тега канарейки
     *
     * @param user
     * @return Promise<void>
     */
    static checkUserTagCanary(user: IUser): Promise<void> {
        const { dispatch }: { dispatch: AppDispatch } = store

        return new Promise((resolve, reject) => {
            dispatch(fetchUserTags({ accountId: user.account_id }))
                .then((data) => {
                    if (IS_PRODUCTION && data?.find((tag) => tag.colored_tag.id === USER_TAG_ID_CANARY_ZONE)) {
                        AppService.setRequestCanaryZone()
                    }

                    resolve()
                })
                .catch(() => {
                    reject()
                })
        })
    }

    /**
     * Auth by SMS
     *
     * @param {confirmSMSAuthPropType} data
     * @return {Promise<authResponseType>}
     */
    confirmSMSAuth(data: confirmSMSAuthPropType): Promise<authResponseType> {
        return this.auth(data)
    }

    /**
     * Auth by phone
     *
     * @param {confirmCallAuthPropType} data
     * @return {Promise<authResponseType>}
     */
    confirmCallAuth(data: confirmCallAuthPropType): Promise<authResponseType> {
        const params: authPropType = {
            grant_type: GRANT_TYPE_CALL,
            package: '',
            phone_id: '',
            client_id: CALL_AUTH_CLIENT_ID,
            client_secret: CALL_CLIENT_SECRET,
        }

        return new Promise((resolve, reject) => {
            const intervalId = window.setInterval(() => {
                if (this.isAbortConfirmCallAuth) {
                    clearCheckers()
                    this.isAbortConfirmCallAuth = false
                    reject()
                }

                this.auth({ ...params, ...data })
                    .then((res) => {
                        clearCheckers()
                        resolve(res)
                    })
                    .catch((err) => {
                        errorLog('confirmCallAuth', err)
                    })
            }, CHECK_CALL_AUTH_TIMER)

            const timeoutId = window.setTimeout(() => {
                clearInterval(intervalId)
                reject({
                    response: {
                        data: {
                            error_description: i18n.t('Call failed. Please try again.'),
                        },
                    },
                })
            }, 600000) // 10min

            function clearCheckers() {
                clearInterval(intervalId)
                clearTimeout(timeoutId)
            }
        })
    }

    /**
     * Отмена проверки авторизации по звонку
     *
     * @return void
     */
    abortConfirmCallAuth(): void {
        if (!this.isAbortConfirmCallAuth) {
            this.isAbortConfirmCallAuth = true
        }
    }
}

export default AuthService
