import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TFireBaseApiError,
    TDynamicLinkProps,
    TDynamicLinkResponse,
} from 'services/AppService'
import { QueryCacheKeys } from 'enums'
import { AppService } from 'services'
import { useAppSelector } from 'store'

export type TFetchFnParams = TDynamicLinkProps
export type TFetchFnError = AxiosError<TFireBaseApiError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TDynamicLinkResponse, TError, TDynamicLinkResponse, TQueryKey>

const key = QueryCacheKeys.dynamicLink

/**
 * Hook Firebase API
 */
export default function useFetchDynamicLink(params: TFetchFnParams, opts: TQueryFnOpts) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return AppService.fetchDynamicLink(params)
            .then((data) => {
                return data
            })
            .catch((err) => {
                const errorMessage = err?.error?.message
                return Promise.reject([errorMessage || t('update_error'), err])
            })
    }, opts)
}
